<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.create_user") }}</h1>
    <breadcrumb class="mt-3 mb-4" />
    <div v-if="formElements" class="card grid p-4">
      <custom-form
        :formElements="formElements"
        formStyle="inline"
        @submitForm="createUser"
      />
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import CustomForm from "../components/form/CustomForm.vue";
export default {
  components: { Breadcrumb, CustomForm },
  setup() {
    const route = useRoute();
    const store = useStore();
    const { t } = useI18n();

    const company = computed(() => store.state.company.company);
    const partner = computed(() => store.state.partner.partner);
    const roles = computed(() => store.state.role.roles);

    const filteredRoles = computed(() =>
      roles.value.filter((role) => {
        if (route.params.companyId && role.label.includes("manager"))
          return role;
        if (route.params.partnerId && role.label.includes("partner"))
          return role;
      })
    );

    const formElements = ref();

    const setFormElements = () => {
      if (route.params.companyId || route.params.partnerId) {
        formElements.value = [
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "input",
            id: "first-name",
            label: "first_name",
            rules: ["isRequired"],
            type: "text",
            value: "",
            variable: "firstName",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "input",
            id: "last-name",
            label: "last_name",
            rules: ["isRequired"],
            type: "text",
            value: "",
            variable: "lastName",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "input",
            id: "email",
            label: "email",
            rules: ["isRequired", "isEmail"],
            type: "text",
            value: "",
            variable: "email",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "select",
            id: "role-select",
            label: "role",
            optionLabel: ["label"],
            options: filteredRoles.value,
            rules: ["isRequired"],
            translatableLabel: "roles",
            value: null,
            variable: "roleId",
          },
          {
            class: "col-span-12 text-right",
            element: "button",
            id: "submit-button",
            label: "save",
            textVariant: "white",
            type: "submit",
            variant: "indigo",
          },
        ];
      } else {
        formElements.value = [
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "input",
            id: "first-name",
            label: "first_name",
            rules: ["isRequired"],
            type: "text",
            value: "",
            variable: "firstName",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "input",
            id: "last-name",
            label: "last_name",
            rules: ["isRequired"],
            type: "text",
            value: "",
            variable: "lastName",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "input",
            id: "email",
            label: "email",
            rules: ["isRequired", "isEmail"],
            type: "text",
            value: "",
            variable: "email",
          },
          {
            class: "col-span-12 text-right",
            element: "button",
            id: "submit-button",
            label: "save",
            textVariant: "white",
            type: "submit",
            variant: "indigo",
          },
        ];
      }
    };

    const createUser = (data) => {
      if (route.path.includes("companies")) {
        data.companyId = route.params.companyId;
        store.dispatch("user/createCompanyUser", data);
      } else if (route.path.includes("partners")) {
        data.companyId = route.params.partnerId;
        store.dispatch("user/createPartnerUser", data);
      } else {
        store.dispatch("user/createEmployee", data);
      }
    };

    onMounted(() => {
      if (
        (route.params.companyId || route.params.partnerId) &&
        !roles.value.length
      ) {
        store.dispatch("role/getRoles");
      }
      if (
        route.params.companyId &&
        (!company.value.id || company.value.id != route.params.companyId)
      ) {
        store.dispatch("company/getCompany", route.params.companyId);
      } else if (
        route.params.partnerId &&
        (!partner.value.id || partner.value.id != route.params.partnerId)
      ) {
        store.dispatch("partner/getPartner", route.params.partnerId);
      }
      setFormElements();
    });

    watch(
      () => roles.value,
      () => {
        setFormElements();
      }
    );

    return {
      createUser,
      formElements,
      t,
    };
  },
};
</script>
